import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const SsdlcVerificationTestingPhasePageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  rtvsection,
  dynamicsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-1"></div>
            <div className="column is-3">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns">
            <div className="column is-6"></div>
            <div className="column is-6">
              <div style={{ maxWidth: 400, height: 50 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${rtvsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "10rem" }}>
            <Title title={rtvsection.title} color={rtvsection.textcolor} />
            <Title title={rtvsection.secondtitle} color={rtvsection.textcolor} />
            <SubTitle title={rtvsection.subheader} color={rtvsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={rtvsection.descriptions} color={rtvsection.textcolor} margin="1rem 0" />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={dynamicsection.title} />
            <SubTitle title={dynamicsection.subheader1} margin="1.5rem 0" />
            <Descriptions descriptions={dynamicsection.descriptions1} margin="1rem 0" />
            <SubTitle title={dynamicsection.subheader2} margin="1.5rem 0" />
            <Descriptions descriptions={dynamicsection.descriptions2} margin="1rem 0" />
          </div>
          <div className="columns" style={{ margin: "2rem 0 1rem" }}>
            <div className="column is-4"></div>
            <div className="column is-4">
              <a href={dynamicsection.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{dynamicsection.buttontxt}</button>
              </a>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

SsdlcVerificationTestingPhasePageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  rtvsection: PropTypes.object,
  dynamicsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SsdlcVerificationTestingPhasePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SsdlcVerificationTestingPhasePageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        rtvsection={frontmatter.rtvsection}
        dynamicsection={frontmatter.dynamicsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SsdlcVerificationTestingPhasePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SsdlcVerificationTestingPhasePage

export const pageQuery = graphql`
  query SsdlcVerificationTestingPhasePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ssdlc-verification-testing-phase" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        rtvsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        dynamicsection {
          title
          subheader1
          descriptions1
          subheader2
          descriptions2
          buttontxt
          buttonlink
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
